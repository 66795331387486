/* tslint:disable */

const localizationConstants = {
	userList: 'userList',
	financialStatement: 'financialStatement',
	filter: 'filter',
	search: 'search',
	addUser: 'addUser',
	userFilter: 'userFilter',
	status: 'status',
	selectStatus: 'selectStatus',
	apply: 'apply',
	reset: 'reset',
	dealerCode: 'dealerCode',
	dealerName: 'dealerName',
	actions: 'actions',
	active: 'active',
	inactive: 'inactive',
	areYouSureToInactiveActive: 'areYouSureToInactiveActive',
	view: 'view',
	edit: 'edit',
	delete: 'delete',
	usersProfile: 'usersProfile',
	phoneNumber: 'phoneNumber',
	enterPhoneNumber: 'enterPhoneNumber',
	areYouSureToDeleteUser: 'areYouSureToDeleteUser',
	dealerFilter: 'dealerFilter',
	region: 'region',
	selectRegion: 'selectRegion',
	pma: 'pma',
	phevRetailer: 'phevRetailer',
	territory: 'territory',
	activated: 'activated',
	dealerPrincipal: 'dealerPrincipal',
	generalManager: 'generalManager',
	dms: 'dms',
	prov: 'prov',
	city: 'city',
	address: 'address',
	pc: 'pc',
	website: 'website',
	legalEntityName: 'legalEntityName',
	dealerPrincipalEmail: 'dealerPrincipalEmail',
	generalManagerEmail: 'generalManagerEmail',
	language: 'language',
	regionClassification: 'regionClassification',
	pvClassification: 'pvClassification',
	dealerCodeValidation: 'dealerCodeValidation',
	firstNameValidation: 'firstNameValidation',
	jobTitleRequired: 'jobTitleRequired',
	validWebsiteUrl: 'validWebsiteUrl',
	faxValidation: 'faxValidation',
	commentsValidation: 'commentsValidation',
	planNameMinLength: 'planNameMinLength',
	planNameMaxLength: 'planNameMaxLength',
	endDateValidation: 'endDateValidation',
	fileSizeValidation: 'fileSizeValidation',
	phoneNumberValidation: 'phoneNumberValidation',
	passwordValidation: 'passwordValidation',
	accountVerificationFailed: 'accountVerificationFailed',
	updateDealer: 'updateDealer',
	dealersProfile: 'dealersProfile',
	noDealerFound: 'noDealerFound',
	monthWiseFinancialStatement: 'monthWiseFinancialStatement',
	noMonthWiseFinancialStatementFound: 'noMonthWiseFinancialStatementFound',
	lastUpdatedOn: 'lastUpdatedOn',
	mitsubishiCanada: 'mitsubishiCanada',
	page: 'page',
	assetsLiabilities: 'assetsLiabilities',
	salesPL: 'salesPL',
	aftersalesPL: 'aftersalesPL',
	miscellaneousIndicators: 'miscellaneousIndicators',
	noFinancialStatementFound: 'noFinancialStatementFound',
	month: 'month',
	totalOpExpense: 'totalOpExpense',
	changeRequest: 'changeRequest',
	submittedOn: 'submittedOn',
	monthYearToBeUpdated: 'monthYearToBeUpdated',
	approve: 'approve',
	reject: 'reject',
	submissions: 'submissions',
	startDateToSubmitFS: 'startDateToSubmitFS',
	lockDateToSubmitFS: 'lockDateToSubmitFS',
	financialStatementSubmissionStatus: 'financialStatementSubmissionStatus',
	actionPlan: 'actionPlan',
	confirmAction: 'confirmAction',
	taskId: 'taskId',
	commentHistory: 'commentHistory',
	viewAction: 'viewAction',
	viewAgenda: 'viewAgenda',
	noActionItemFound: 'noActionItemFound',
	pending: 'pending',
	clearAll: 'clearAll',
	signOut: 'signOut',
	welcome: 'welcome',
	close: 'close',
	areYouSureTo: 'areYouSureTo',
	user: 'user',
	dealer: 'dealer',
	firstName: 'firstName',
	lastName: 'lastName',
	email: 'email',
	jobTitle: 'jobTitle',
	verified: 'verified',
	uploadImage: 'uploadImage',
	role: 'role',
	select: 'select',
	confirm: 'confirm',
	cancel: 'cancel',
	update: 'update',
	add: 'add',
	enter: 'enter',
	noOptionFound: 'noOptionFound',
	no: 'no',
	found: 'found',
	users: 'users',
	dealers: 'dealers',
	closeData: 'closeData',
	reports: 'reports',
	visitManagement: 'visitManagement',
	businessPlan: 'businessPlan',
	notifications: 'notifications',
	myProfile: 'myProfile',
	changePassword: 'changePassword',
	logout: 'logout',
	enterYour: 'enterYour',
	oldPassword: 'oldPassword',
	newPassword: 'newPassword',
	confirmPassword: 'confirmPassword',
	phone: 'phone',
	fax: 'fax',
	generalMangerGM: 'generalMangerGM',
	copied: 'copied',
	all: 'all',
	submitted: 'submitted',
	reSubmitted: 'reSubmitted',
	downloaded: 'downloaded',
	visit: 'visit',
	markAllAsRead: 'markAllAsRead',
	notificationsNotAvailable: 'notificationsNotAvailable',
	showMore: 'showMore',
	yayYouHaveSeenItAll: 'yayYouHaveSeenItAll',
	selectDealer: 'selectDealer',
	downloadFS: 'downloadFS',
	generatingFS: 'generatingFS',
	pleaseHoldOn: 'pleaseHoldOn',
	year: 'year',
	fsContainsNegativeVal: 'fsContainsNegativeVal',
	salesGrossProfit: 'salesGrossProfit',
	afterSalesGrossProfit: 'afterSalesGrossProfit',
	closedData: 'closedData',
	yes: 'yes',
	request: 'request',
	lock: 'lock',
	unlock: 'unlock',
	agendaAndAction: 'agendaAndAction',
	publishAndShare: 'publishAndShare',
	visitCode: 'visitCode',
	visitDate: 'visitDate',
	createdBy: 'createdBy',
	conductedBy: 'conductedBy',
	department: 'department',
	visitType: 'visitType',
	agendaItems: 'agendaItems',
	actionItems: 'actionItems',
	addAVisit: 'addAVisit',
	visitFilter: 'visitFilter',
	visitStartEndDate: 'visitStartEndDate',
	participants: 'participants',
	agendaItem: 'agendaItem',
	actionItem: 'actionItem',
	agenda: 'agenda',
	addNewAgenda: 'addNewAgenda',
	addAgenda: 'addAgenda',
	noAgendaItemFound: 'noAgendaItemFound',
	selectedAgendaItems: 'selectedAgendaItems',
	removeMonoAgenda: 'removeMonoAgenda',
	title: 'title',
	id: 'id',
	assignTo: 'Assigned To',
	dueDate: 'dueDate',
	addActionItems: 'addActionItems',
	areYouSureYouWantToDelete: 'areYouSureYouWantToDelete',
	save: 'save',
	saveAndNext: 'saveAndNext',
	next: 'next',
	visitConductedBy: 'visitConductedBy',
	startDateTime: 'startDateTime',
	endDateTime: 'endDateTime',
	addParticipants: 'addParticipants',
	selectDate: 'selectDate',
	selectTime: 'selectTime',
	searchParticipant: 'searchParticipant',
	noParticipants: 'noParticipants',
	comment: 'comment',
	internalComment: 'internalComment',
	writeYourInternalMsg: 'writeYourInternalMsg',
	dashboard: 'dashboard',
	noReportFound: 'noReportFound',
	new: 'new',
	parts: 'parts',
	totalOperations: 'totalOperations',
	used: 'used',
	service: 'service',
	bodyShop: 'bodyShop',
	balanceSheet: 'balanceSheet',
	workingCapital: 'workingCapital',
	powerBIReport: 'powerBIReport',
	bizDrivePoweredByAutoRuptiv: 'bizDrivePoweredByAutoRuptiv',
	createNewPlan: 'createNewPlan',
	category: 'category',
	commentForRejection: 'commentForRejection',
	typeYourFeedbackOrReasonForRejection: 'typeYourFeedbackOrReasonForRejection',
	planID: 'planID',
	planName: 'planName',
	createdDate: 'createdDate',
	submission: 'submission',
	downloadBusinessPlanPDF: 'downloadBusinessPlanPDF',
	approved: 'approved',
	rejected: 'rejected',
	pendingApproval: 'pendingApproval',
	draft: 'draft',
	range: 'range',
	PV: 'PV',
	PVRegionDealer: 'PVRegionDealer',
	noCommentsFound: 'noCommentsFound',
	totalExpenses: 'totalExpenses',
	KPIDescription: 'KPI Description',
	actual: 'actual',
	target: 'target',
	history: 'history',
	submitForApproval: 'submitForApproval',
	businessPlanDetails: 'businessPlanDetails',
	details: 'details',
	create: 'create',
	generate: 'generate',
	previous: 'previous',
	selectBaseline: 'selectBaseline',
	chooseOneOption: 'chooseOneOption',
	dealerOrRegionOrPV: 'dealerOrRegionOrPV',
	businessPlanForYear: 'businessPlanForYear',
	isAlreadyCreatedDoYouWantToViewIt: 'isAlreadyCreatedDoYouWantToViewIt',
	option: 'option',
	facilitator: 'facilitator',
	by: 'by',
	on: 'on',
	noHistoryFound: 'noHistoryFound',
	at: 'at',
	annualTarget: 'annualTarget',
	writeYourCommentHere: 'writeYourCommentHere',
	imageISUploading: 'imageISUploading',
	assign: 'assign',
	agendaItemValidation: 'agendaItemValidation',
	deleteActionItem: 'deleteActionItem',
	assignee: 'assignee',
	here: 'here',
	tagUser: 'tagUser',
	replies: 'replies',
	reply: 'reply',
	closeThread: 'closeThread',
	hide: 'hide',
	priority: 'priority',
	uploadedFiles: 'uploadedFiles',
	name: 'Name',
	uploadActionDocs: 'uploadActionDocs',
	noFileChosen: 'noFileChosen',
	deleteAgendaItem: 'deleteAgendaItem',
	areYouSureRemoveAgendaItem: 'areYouSureRemoveAgendaItem',
	send: 'send',
	uploadFiles: 'uploadFiles',
	high: 'high',
	medium: 'medium',
	low: 'low',
	roleInformation: 'roleInformation',
	online: 'online',
	call: 'call',
	onsite: 'onsite',
	toDo: 'toDo',
	inProgress: 'inProgress',
	completed: 'completed',
	fieldRequired: 'fieldRequired',
	invalidEmailAddress: 'invalidEmailAddress',
	isRequired: 'isRequired',
	charValidation50: 'charValidation50',
	charValidation100: 'charValidation100',
	passwordNotMatched: 'passwordNotMatched',
	password: 'password',
	actionDocument: 'actionDocument',
	duplicateFileError: 'duplicateFileError',
	maxFileUploadValidation5: 'maxFileUploadValidation5',
	maxFileUploadValidation10: 'maxFileUploadValidation10',
	fileLimitValidation: 'fileLimitValidation',
	invalidImgTypeMsg: 'invalidImgTypeMsg',
	noBusinessPlanFound: 'noBusinessPlanFound',
	areYouSureYouWantToLogout: 'areYouSureYouWantToLogout',
	visitSummaryReport: 'visitSummaryReport',
	signature: 'signature',
	clear: 'clear',
	trim: 'trim',
	pendingSignature: 'pendingSignature',
	saveYourLatestChanges: 'saveYourLatestChanges',
	doYouWantSaveChanges: 'doYouWantSaveChanges',
	firstNameRequired: 'firstNameRequired',
	lastNameRequired: 'lastNameRequired',
	selectRole: 'selectRole',
	from: 'from',
	to: 'to',
	atlantic: 'atlantic',
	bc: 'bc',
	mitsubishi: 'mitsubishi',
	ontario: 'ontario',
	quebec: 'quebec',
	western: 'western',
	total: 'total',
	discard: 'discard',
	startDateValidation: 'startDateValidation',
	actionItemReport: 'actionItemReport',
	noDataAvailable: 'noDataAvailable',
	totalVisit: 'totalVisit',
	totalActionItems: 'totalActionItems',
	lookup: 'lookup',
	searchAccIdOrKPI: 'searchAccIdOrKPI',
	helpNotAvailable: 'helpNotAvailable',
	dealerType: 'dealerType',
	prospect: 'prospect',
	addNewDealerOrProspect: 'addNewDealerOrProspect'
};

export default localizationConstants;
