import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeProps, Box, IconButton, styled } from '@mui/material';
import { Notifications as NotificationsIcon, HelpRounded as HelpIcon } from '@mui/icons-material';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

import HttpService from 'shared/services/Http.service';
import { createAction } from 'shared/util/Utility';
import { refreshLanguage } from 'shared/util/localization';
import MMSCANTooltip from 'shared/components/MMSCANToolTip/MMSCANToolTip';
import { API_CONFIG } from 'shared/constants/api';
import { MODULE_ROUTES } from 'shared/constants/constant';
import localizationConstants from 'shared/util/translation/constant';
// Type/Interface/Component
import { IDealer } from 'shared/interface';
import { IState } from 'shared/interface/state';
import * as actionTypes from 'store/action-types';
import { IModuleDetails, IRole, IUserData } from 'features/auth/login/LoginScreen.type';
import { LayoutProps } from './Layout.types';
import DrawerContent from './component/DrawerContent';
import ProfileMenu from './component/ProfileMenu';
import Notification from './component/Notification';
import AutoRefresh from './component/AutoRefresh';
import HelpDrawer from './component/lookup';

// Styles
import {
	ChildrenBoxStyles,
	Drawer,
	DrawerBoxStyles,
	DrawerHeader,
	StyledIconButton,
	StyledSwitch
} from './Layout.styles';
import * as Sx from './component/LayoutHeader.style';

// Assets
import { MMSCANLogo, MenuIcon, MenuOpenIcon } from 'assets/images/icon';
import { colors } from 'assets/styles/MMSCANTheme';

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
	color: colors.primary.main,
	'& .MuiBadge-badge': {
		right: 0,
		top: 0,
		backgroundColor: colors.grey.shade3,
		padding: '0 4px'
	}
}));

const Layout: FC<LayoutProps> = (props: LayoutProps) => {
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const authData = useSelector((state: IState) => state.auth);
	const role = authData.role as IRole;
	const modules = authData.modules as IModuleDetails[];
	const firstName = authData?.first_name ?? '';
	const lastName = authData?.last_name ?? '';

	const [isSideNavOpen, setIsSideNavOpen] = useState(false);
	const [updateNavigation, setUpdateNavigation] = useState('');
	const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
	const [isOpenHelpDrawer, setIsOpenHelpDrawer] = useState<boolean>(false);
	const [unreadCount, setUnreadCount] = useState<number>(0);
	const [accessModules, setAccessModules] = useState(modules);
	const [showAutoRefreshPopup, setShowAutoRefreshPopup] = useState(false);
	const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
	const [checked, setChecked] = useState(localStorage.getItem('lang') === 'fr');

	const getUserProfile = async () => {
		try {
			const result = await HttpService.get(API_CONFIG.userProfile);
			const { first_name, last_name, email, role_id, dealer, job_title, version } = result.data;
			const currentVersion = localStorage.getItem('version');

			if (currentVersion !== version && !isUndefined(currentVersion) && !isNull(currentVersion)) {
				setShowAutoRefreshPopup(true);
			}
			localStorage.setItem('version', version);

			const userProfileDetails = {
				first_name,
				last_name,
				email,
				job_title,
				role_id,
				dealer_id: dealer.map((dealer: IDealer) => dealer.dealer_id)
			};

			dispatch(createAction(actionTypes.AUTH_SUCCESS, { ...(authData as IUserData), ...userProfileDetails }));
		} catch (error) {
			console.error(error);
		}
	};

	const handleUnreadCount = (count: number) => setUnreadCount(count);

	useEffect(() => {
		pathname !== '/my-profile' && getUserProfile();
	}, [pathname]);

	useEffect(() => {
		if (modules) {
			// Filter modules to get those with non-empty permissions
			const filteredModules = modules.find((module) => !isEmpty(module.permissions));
			// Set the navigation to the route corresponding to the first found module
			setAccessModules(modules.filter((module) => module));
			filteredModules && setUpdateNavigation(MODULE_ROUTES[filteredModules.module_name]);
		}
	}, [authData.user_id, modules]);

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setIsTooltipOpen(false);
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	const renderIconButton = (id: string, isOpen: boolean, icon: any) => (
		<StyledIconButton onClick={() => setIsSideNavOpen(isOpen)} id={id} data-testid={id} open={isSideNavOpen}>
			{icon}
		</StyledIconButton>
	);

	const isNotificationPermission = useCallback(
		(modules: { module_name: string; permissions: string[] }[]): boolean =>
			modules?.some((module) => module.module_name === 'notification' && module.permissions.length > 0),
		[]
	);

	// Update localStorage and state when the switch is toggled
	const handleChange = (event: { target: { checked: boolean } }) => {
		setChecked(event.target.checked);
		localStorage.setItem('lang', event.target.checked ? 'fr' : 'en');
		refreshLanguage(event.target.checked ? 'fr' : 'en');
		window.location.reload();
	};

	useEffect(() => {
		// Sync state with localStorage on mount in case localStorage was updated externally
		const savedLang = localStorage.getItem('lang');
		setChecked(savedLang === 'fr');
	}, []);

	return (
		<>
			{showAutoRefreshPopup && process.env['REACT_APP_AUTO_REFRESH'] === 'true' && <AutoRefresh />}
			<Box sx={{ display: 'flex' }}>
				<Drawer
					variant='permanent'
					open={isSideNavOpen}
					sx={{
						'.MuiPaper-root': {
							background: colors.black.main
						}
					}}
				>
					<Box>
						<DrawerHeader open={isSideNavOpen}>
							<Box sx={Sx.logoWrapper}>
								<Box sx={DrawerBoxStyles()} onClick={() => navigate(updateNavigation)}>
									<MMSCANLogo height='100%' width='100%' />
								</Box>
							</Box>
							{renderIconButton(
								isSideNavOpen ? 'openDrawer' : 'closeDrawer',
								!isSideNavOpen,
								isSideNavOpen ? <MenuOpenIcon /> : <MenuIcon />
							)}
						</DrawerHeader>
						<DrawerContent
							isOpen={isSideNavOpen}
							setIsOpen={setIsSideNavOpen}
							pathname={pathname}
							modules={accessModules}
						/>
					</Box>
					<Box>
						<Box
							sx={{
								...Sx.infoRoot,
								margin: isSideNavOpen ? '2px 10px' : '2px 7px',
								justifyContent: isSideNavOpen ? 'start' : 'center'
							}}
							data-testid='languageInfo'
						>
							<StyledSwitch
								checked={checked}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'language toggle' }}
							/>
						</Box>
						{!authData.is_prospect && (
							<Box
								sx={{
									...Sx.infoRoot,
									margin: isSideNavOpen ? '2px 15px' : '2px 7px',
									justifyContent: isSideNavOpen ? 'start' : 'center'
								}}
								data-testid='lookup'
							>
								<MMSCANTooltip title={t(localizationConstants.lookup)} arrow offset={[0, -15]}>
									<IconButton onClick={() => setIsOpenHelpDrawer(true)} data-testid='lookup'>
										<StyledBadge>
											<HelpIcon
												fontSize='medium'
												sx={{
													marginLeft: '3px',
													height: '30px',
													width: '30px'
												}}
											/>
										</StyledBadge>
									</IconButton>
								</MMSCANTooltip>
							</Box>
						)}
						{isNotificationPermission(modules) && (
							<Box
								sx={{
									...Sx.infoRoot,
									margin: isSideNavOpen ? '0 15px 2px' : '0 7px 2px',
									justifyContent: isSideNavOpen ? 'start' : 'center'
								}}
								data-testid='notification'
							>
								<MMSCANTooltip
									title={t(localizationConstants.notifications)}
									arrow
									open={isTooltipOpen}
									onMouseEnter={() => setIsTooltipOpen(true)}
									onMouseLeave={() => setIsTooltipOpen(false)}
								>
									<IconButton onClick={() => setIsOpenDrawer(true)} data-testid='notificationButton'>
										<StyledBadge badgeContent={unreadCount > 0 ? unreadCount : 0} max={99}>
											<NotificationsIcon
												fontSize='medium'
												sx={{ height: '30px', width: '30px' }}
											/>
										</StyledBadge>
									</IconButton>
								</MMSCANTooltip>
							</Box>
						)}
						<ProfileMenu
							isOpen={isSideNavOpen}
							firstName={firstName}
							lastName={lastName}
							roleColor={role?.role_color || ''}
						/>
					</Box>
				</Drawer>
				<Box component='main' sx={ChildrenBoxStyles(isSideNavOpen)}>
					{props.children}
				</Box>
			</Box>
			{(isNotificationPermission(modules) || !authData.is_prospect) && (
				<Notification
					isOpen={isOpenDrawer}
					handleNotificationToggle={() => setIsOpenDrawer(!isOpenDrawer)}
					handleUnreadCount={handleUnreadCount}
					unreadCount={unreadCount}
				/>
			)}
			{isOpenHelpDrawer && (
				<HelpDrawer
					isOpen={isOpenHelpDrawer}
					handleNotificationToggle={() => setIsOpenHelpDrawer(!isOpenHelpDrawer)}
				/>
			)}
		</>
	);
};

export default Layout;
