import localizationConstants from 'shared/util/translation/constant';
import { Role } from './enum';
import { IDropDownOption, IObj } from 'shared/interface';
import { IUserInputField } from 'features/user/UserList.type';
import { colors } from 'assets/styles/MMSCANTheme';

export const API_BASE_URL = process.env['REACT_APP_BASE_URL'];
export const ENCRYPTION_KEY = process.env['REACT_APP_ENCRYPTION_KEY'];
export const EDITOR_KEY = process.env['REACT_APP_LICENSE_KEY'];

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const PASSWORD_REGEX =
	/^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[A-Z])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,16}$/;
export const PHONE_REGEX = /^[0-9\s()+-]{8,12}$/;
export const FAX_REGEX = /^[0-9\s()+-]{8,20}$/;
export const WEBSITE_REGEX =
	/^(?:(?:https?:\/\/)?(?:www\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(?::\d{1,5})?(?:\/[^\s]*)?$/; // eslint-disable-line
export const NOT_NUMBER_REGEX = /^[a-zA-Z@:%._~#=]$/;

export const DEALER_CODE_REGEX = /^\d{5}$/;

export const USER_EMAIL = 'admin@gmail.com';
export const USER_PASSWORD = 'Password@123';

export const MOCK_API_MODULES = {
	LOGIN: 'LOGIN',
	FORGOT_PASSWORD: 'FORGOT_PASSWORD',
	RESET_PASSWORD: 'RESET_PASSWORD',
	MASTER: 'MASTER',
	VERIFY_ACCOUNT: 'VERIFY_ACCOUNT',
	USER: 'USER',
	NOTIFICATION: 'NOTIFICATION',
	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
	INDUSTRY_SALES: 'INDUSTRY_SALES',
	MY_PROFILE: 'MY_PROFILE',
	MY_SALES: 'MY_SALES'
};

export const ROUTES = {
	// Auth routes
	LOGIN: '/login',
	FORGOT_PASSWORD: '/forgot-password',
	RESET_PASSWORD: '/reset-password/:token/:userId',
	VERIFY_ACCOUNT: '/verify-account/:token/:userId',

	/* Permission routes */
	DASHBOARD: '/dashboard',
	MY_PROFILE: '/my-profile',
	CHANGE_PASSWORD: '/change-password',
	//User
	USER: '/user',
	ADD_USER: '/user/add',
	EDIT_USER: '/user/edit',
	//Dealer
	DEALER_SESSION_MANAGEMENT: '/dealer',
	EDIT_DEALER: '/dealer/edit',
	ADD_DEALER: '/dealer/add',
	//FS
	SUBMISSION: '/month-wise-financial-statement',
	FINANCIAL_STATEMENT: '/financial-statement',
	//Close data
	CLOSED_DATA: '/close-data',
	CHANGE_REQUEST: '/close-data/change-request',
	//Visit management
	VISIT_MANAGEMENT: '/visit-management',
	VISIT: '/visit-management/visit',
	ACTION: 'action',
	AGENDA_ACTION: 'agenda-action',
	VISIT_SUMMARY_REPORT: '/visit-management/summary-report',
	ACTION_ITEM_REPORT: '/visit-management/action-item-report',
	//Reports
	REPORTS: '/reports',
	NEW: '/reports/new',
	PARTS: '/reports/parts',
	USED: '/reports/used',
	SERVICE: '/reports/service',
	BODY_SHOP: '/reports/body-shop',
	TOTAL_OPERATIONS: '/reports/total-operations',
	BALANCE_SHEET: '/reports/balance-sheet',
	WORKING_CAPITAL: '/reports/working-capital',
	//Business plan
	BUSINESS_PLAN: '/business-plan',
	BUSINESS_PLAN_DETAILS: '/business-plan/details',
	BUSINESS_PLAN_HISTORY: '/business-plan/history',

	EMBED_REPORTS: '/power-bi-embed-report',

	ACCESS_DENIED: '/access-denied'
};

export const MODULE = {
	DASHBOARD: 'dashboard',
	USER: 'user',
	DEALER_SESSION_MANAGEMENT: 'dealer',
	FINANCIAL_STATEMENT: 'financial-statement',
	REPORTS: 'reports',
	SUBMISSION: 'month-wise-financial-statement',
	CLOSED_DATA: 'close-data',
	CHANGE_REQUEST: 'close-data/change-request',
	VISIT_MANAGEMENT: 'visit-management',
	BUSINESS_PLAN: 'business-plan',
	EMBED_REPORTS: 'embed-report'
};

export const PERMISSION = {
	LIST: 'list',
	ADD: 'Add',
	UPDATE: 'Update',
	DELETE: 'delete',
	HISTORY: 'history',
	VIEW: 'view',
	DOWNLOAD: 'download'
};

export const MODULE_ROUTES: { [key: string]: any } = {
	dashboard: ROUTES.DASHBOARD,
	user: ROUTES.USER,
	dealer: ROUTES.DEALER_SESSION_MANAGEMENT,
	'financial-statement': ROUTES.FINANCIAL_STATEMENT
};

export const RECORDS_PER_PAGE_OPTIONS = [
	{ label: 10, value: 10 },
	{ label: 30, value: 30 },
	{ label: 50, value: 50 },
	{ label: 100, value: 100 }
];

export const DEFAULT_PARAMS = {
	current_page: 1,
	record_per_page: 30
};

export const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB

export const ALLOW_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];

export const INITIAL_MODAL_STATE = {
	open: false,
	type: ''
};

export const STATUS_FILTER_OPTION = [
	{ label: localizationConstants.active, value: 'true' },
	{ label: localizationConstants.inactive, value: 'false' }
];

export const MAX_FILE_SIZE_MESSAGE = localizationConstants.fileLimitValidation;
export const INVALID_IMAGE_TYPE_MESSAGE = localizationConstants.invalidImgTypeMsg;

export const MAX_ACTION_ITEM_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const MAX_ACTION_ITEM_FILE_SIZE_MESSAGE = localizationConstants.fileSizeValidation;

export const FILTERED_KEY_IGNORE = ['current_page', 'record_per_page', 'sort_by', 'order_by'];

export const FS_PAGES: string[] = ['1', '2', '3', '4', '5', '6'];

export const userInfoInput: IUserInputField[] = [
	{
		title: localizationConstants.firstName,
		name: 'first_name',
		errorTitle: localizationConstants.firstNameRequired,
		textStyle: 'capitalize',
		isRequired: true
	},
	{
		title: localizationConstants.lastName,
		name: 'last_name',
		errorTitle: localizationConstants.lastNameRequired,
		textStyle: 'capitalize',
		isRequired: true
	},
	{
		title: localizationConstants.email,
		name: 'email',
		errorTitle: localizationConstants.email,
		isRequired: true
	}
];

export const userPhoneInput: IUserInputField[] = [
	{
		title: localizationConstants.phoneNumber,
		name: 'phone',
		errorTitle: localizationConstants.phoneNumber,
		isRequired: false
	}
];

export const userRoleInput: IUserInputField[] = [
	{
		title: localizationConstants.role,
		name: 'role_id',
		errorTitle: localizationConstants.role,
		isSelect: true,
		isRequired: true
	},
	{
		title: localizationConstants.jobTitle,
		name: 'job_title',
		errorTitle: localizationConstants.jobTitle,
		isSelect: true,
		isRequired: true
	},
	{
		title: localizationConstants.dealers,
		name: 'dealer_id',
		errorTitle: localizationConstants.dealer,
		isMultiSelect: true,
		isRequired: true
	}
];

export const userFormInitialValues = {
	first_name: '',
	last_name: '',
	email: '',
	dealer_id: [],
	role_id: '',
	job_title: '',
	phone_number: ''
};

export const userInputForm = [...userInfoInput, ...userPhoneInput, ...userRoleInput];
export const profileInputForm = [...userInfoInput, ...userRoleInput];

export const defaultDropDownOption: IDropDownOption = { label: '', value: '' };

export const capitalTableHeadArr = ['first_name', 'last_name', 'pma'];

export const FS_ACTIVE_PATHS = ['/month-wise-financial-statement', '/financial-statement'];

export const ALLOWED_PROSPECT_MODULES = ['business-plan'];

export const NotificationType = {
	All: '',
	Submitted: 'submitted',
	'Re-Submitted': 're-submitted',
	Downloaded: 'downloaded',
	Visit: 'visit'
};

export const NotificationBGColor = {
	submitted: colors.secondary.shade10,
	're-submitted': colors.secondary.shade11,
	downloaded: '#df47473b',
	visit: '#c7d1d6'
};

export const FSActions = ['downloaded', 'submitted', 're-submitted'];
export const NotificationActionFilter = [
	{ label: localizationConstants.all, value: 'All' },
	{ label: localizationConstants.submitted, value: 'Submitted' },
	{ label: localizationConstants.reSubmitted, value: 'Re-Submitted' },
	{ label: localizationConstants.downloaded, value: 'Downloaded' },
	{ label: localizationConstants.visit, value: 'Visit' }
];

export const FS_PAGE_TOOLTIP: IObj = {
	'page 1': localizationConstants.assetsLiabilities,
	'page 2': localizationConstants.salesPL,
	'page 3': localizationConstants.aftersalesPL,
	'page 4': localizationConstants.salesGrossProfit,
	'page 5': localizationConstants.afterSalesGrossProfit,
	'page 6': localizationConstants.miscellaneousIndicators
};

export const titleMap = [
	{ path: '/', title: 'BizDrive' },
	{ path: '/dashboard', title: 'BizDrive' },
	{ path: '/user', title: 'BizDrive - Users' },
	{ path: '/dealer', title: 'BizDrive - Dealers' },
	{ path: '/month-wise-financial-statement', title: 'BizDrive - Financial Statement' },
	{ path: '/close-data', title: 'BizDrive - Closed Data' },
	{ path: '/my-profile', title: 'BizDrive - My Profile' },
	{ path: '/change-password', title: 'BizDrive - Change Password' },
	{ path: '/visit-management', title: 'BizDrive - Visit Management' },
	{ path: '/reports', title: 'BizDrive - Reports' },
	{ path: '/business-plan', title: 'BizDrive - Business Plan' },
	{ path: '/login', title: 'BizDrive' }
];

export const LanguageCodes: IObj = {
	English: 'en',
	French: 'fr'
};

export const ALL_ACCESS_ROLES: string[] = [Role.Admin || Role.Special_previledges];

export const ALL = 'All';
